<template>
  <div class="page-container" v-if="loadedData">
    <div class="grid">
      <div class="centered-flex">
        <h2 class="title">{{ loadedData.title }}</h2>
        <h3 class="subtitle">{{ loadedData.subtitle.toUpperCase() }}</h3>
        <p class="description">
          {{ loadedData.description1 }}
        </p>
      </div>
      <h2 class="quote italic">{{ loadedData.quote }}</h2>
      <div class="centered-flex">
        <p class="description-2">
          {{ loadedData.description2 }}
        </p>
        <h3 class="values-title">{{ loadedData.valuesTitle.toUpperCase() }}</h3>
        <div class="values-list">
          <h2
            v-for="(value, index) in loadedData.values"
            :key="value.value"
            class="value"
            :class="{ smaller: index !== selectedValueIndex }"
            @click="selectedValueIndex = index"
          >
            {{ value.title }}
          </h2>
        </div>
        <Transition name="fade" mode="out-in">
          <div class="descriptions">
            <p
              v-for="(value, index) in loadedData.values"
              :key="value.value"
              class="value-description"
              :class="{ unselected: index !== selectedValueIndex }"
            >
              {{ loadedData.values[index].description }}
            </p>
          </div>
        </Transition>
        <h3 class="equipe">{{ loadedData.subtitle2 }}</h3>
        <p class="description-3">
          {{ loadedData.description3 }}
        </p>
        <div class="ctas">
          <div class="cta-btn" @click="$emit('goToNext')">
            <RacButton :text="loadedData.callToAction"/>
          </div>
          <div class="cta-btn" v-if="loadedData.callToAction2">
            <RacButton
              :text="loadedData.callToAction2"
              :invert="true"
              @click="showLowerAndJobPanel"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RacButton from "../Atoms/RacButton";
import { TEAM_PAGE_QUERY } from "@/gql-operations/allGqlOperations";

export default {
  name: "teamPage",
  components: { RacButton },
  emits: ["close", "goToNext"],
  apollo: {
    getData: {
      query: TEAM_PAGE_QUERY,
      update(data) {
        this.loadedData = data.pages.nodes[0].teamPageFields?.content;
      },
    },
  },
  data() {
    return {
      loadedData: null,
      selectedValueIndex: 0,
    };
  },
  methods: {
    showLowerAndJobPanel() {
      this.$store.commit("showLowerPanel", true);
      this.$store.commit("activateJobPanel", true);
    },
  },
};
</script>

<style scoped lang="scss">
.page-container {
  .grid {
    @include right-panel-grid;

    .centered-flex {
      display: flex;
      flex-direction: column;
      grid-column: 2 / span 10;

      .title {
        margin-bottom: 10vh;
      }
      .subtitle {
        color: $orange;
        margin-bottom: 3rem;
      }

      .description {
        white-space: pre-wrap;
      }

      .description-2,
      .description-3 {
        white-space: pre-wrap;
        margin-bottom: 9vh;
      }

      .values-title,
      .equipe {
        color: $orange;
        margin-bottom: 3rem;
      }

      .values-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 3rem;

        .value {
          cursor: pointer;
          transition: font-size 0.3s;
        }

        .smaller {
          font-size: 22px;
          color: grey;
        }
      }

      .descriptions {
        display: grid;
        margin-bottom: 9vh;
        .value-description {
          grid-row: 1;
          grid-column: 1;
          opacity: 1;
          transition: opacity 0.3s;
        }
        .unselected {
          opacity: 0;
          position: relative;
        }
      }

      .ctas {
        display: flex;
        gap: 3rem;
      }
    }

    .quote {
      grid-column: 1 / span 12;
      text-align: center;
      margin: 5vh 0 5vh 0;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@media screen and (max-width: $xs-screen) {
  .page-container {
    .grid {
      .centered-flex {
        grid-column: 1 / span 12;

        .values-list {
          flex-wrap: wrap;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
